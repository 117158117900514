<template>
    <div>
        <div class="title">
            车辆校验
        </div>
        <div class="warper" >
            <div class="listbox" v-for="(item,i) in dataList" :key="item.id">
                <h3>{{item.name}}</h3>
                <div>
                    <p>进场前图片：</p>
                    <van-image v-for="(i,index) in item.value" :key="index" width="100" height="100" :src="i" />
                </div>
                <!-- <div>
                    <p>进场后图片：</p>
                    <div v-if="item.objection_value.length!=0">

                        <van-image v-for="(i,index) in item.objection_value" :key="index" width="100" height="100" :src="i" />
                    
                    </div>
                    <span v-else>暂无照片</span>
                </div> -->
                <!-- <van-button type="warning">添加照片异常</van-button> -->
                <van-cell center title="是否有异常">
                    <template #right-icon>
                        <van-switch v-model="item.checked" size="24" @change="checkedChange($event,i)" />
                    </template>
                </van-cell>
                <div class="yichang" v-if="item.checked ">
                    <!-- <p style="color:red">上传异常照片:</p> -->
                    <uploadingImg ref="business_license" :isTrue="false" :objectionValue="item.picker_objection_value" @imgindex="imgindex($event,i)"  />

                    <van-field class="bb" v-model="item.picker_objection_remark" label="异常备注" />
                </div>
            </div>
            <div class="mt-10">
                <van-button type="warning" @click="chu">清除</van-button>
                <div v-if="qianming" class="img"> 
                   
                    <van-image  :src="qianming" />
                </div>
                <div  v-if="!qianming">
                    <signature ref="tureRef" @isFile="isFile"  />
                </div>
            </div>
            <div class="btn">
                <van-button type="info" @click="nextGo">提交</van-button>
            </div>
        </div>
        <van-popup
            v-model="show"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',}"
            >
            <TrailerInfo @CarCloes="CarCloes" ref="InfoRef" status="isAudit" />
        </van-popup>
    </div>
</template>

<script>
import {checkInformationFind,submitCheck} from '@/api/out'

import uploadingImg from '@/components/up/web_up'

import TrailerInfo from '@/components/TrailerInfo'
import signature from '@/components/signature'
    export default {
        components: {
            uploadingImg,signature,TrailerInfo
        },
        data() {
            return {
                dataList:[],
                checked:false,
                show:false,
                qianming:''
            }
        },
        watch: {
            dataList: {
                immediate: true,
                deep: true,
                handler(newValue, oldValue) {
                    if(newValue.length!=0){
                        newValue.forEach(i => {
                            if(i.picker_objection_value && i.picker_objection_value.length !=0){
                                i.checked = true
                            }
                        });
                    }
                }
            }
        },
        
        mounted () {
           
                this.getInfo();
            
        },
        methods: {
            CarCloes(){
                this.show = false
                this.getInfo();
            },
            //清除画布签名
            chu(){
                if(this.qianming){

                    this.qianming = ''
                }else{

                    this.$refs.tureRef.clearSignImg()
                }
            },
          
            //关闭胶南按钮
            checkedChange(e,i){
                console.log(e)
                if(!e){
                    this.dataList[i].picker_objection_value = []
                    this.dataList[i].picker_objection_remark = []
                    this.$nextTick(()=>{
                        this.$refs.business_license.WXimg = []
                    })
                }
            },
            //获取照片后的提交
            async isFile(e){
                let params = new FormData(); //创建form对象
                    params.append("file", e);
                    this.$http.post("/index.php/index/web_upload/index", params).then((res) => {
                        if(res.data.code == 0){
                            this.obsubmit(res.data.data)
                            
                        }
                    })
               
            },
            async obsubmit(img){
                try{
                    let obj = {
                        id:this.$route.query.id,
                        objection_data:this.dataList,
                        picker_objection_signature:img
                    }
                    const {data} = await this.$http.post('index.php/api/submit_check',obj).then(res=>res)
                    // this.show = true
                    // this.$nextTick(()=>{
                    //     this.$refs.InfoRef.getInfo(this.$route.query.id)
                    // })
         
                    if(data.code == 0){

                        this.$router.push({path:'/InfoImg',query:{id:this.$route.query.id}})
                        this.$toast.success(data.msg)
                    }else{
                        this.$toast.fail(data.msg)
                    }
                }catch{}
            },
            //获取异常照片
            imgindex(i,val){
                console.log(i,val)
                this.dataList[val].picker_objection_value = i
            },
            //提交信息
            nextGo(){
                if(this.qianming){
                    this.obsubmit(this.qianming)
                }else{
                    this.$refs.tureRef.saveSignImg('NoJY')
                }
                
                
                
            },  
            //获取信息
            async getInfo() {
                try{
                    const {data} = await this.$http.post('/index.php/api/check_find',{id:this.$route.query.id}).then(res=>res)
                    console.log(data)
                    this.dataList = data.data.image
                    this.qianming = data.data.picker_objection_signature
                }catch{}
                
            }
        },
    }
</script>

<style lang="scss" scoped>
.title{
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    text-align: center;
}
.warper{
    margin-top: 55px;
    padding: 10px;
    .listbox{
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        .yichang{
            
            .bb{
                border-bottom: 1px solid rgb(207, 200, 200);
            }
        }
    }
}
.btn{
    text-align: center;
    margin-top: 20px;
    .van-button{
        width: 150px;
    }
}
.img{
    background: #eee;
    position: relative;
}
</style>